<template>
  <div class="row mb-3 align-items-center">
    <div class="col">
      <h4>
        {{ $t("countries.currencies") }}
      </h4>
    </div>
    <div class="col-6 col-md-4">
      <input
        type="search"
        class="form-control py-0"
        v-model="filter"
        :placeholder="`Search currencies..`"
      />
    </div>
    <div class="col-auto">
      <button
        type="button"
        class="btn btn-type-4 btn-small"
        v-on:click="exchangeRateModal"
      >
        <span>
          {{ $t("currencies.exchangeRates") }}
        </span>
      </button>
    </div>
  </div>
  <div class="row">
    <div
      v-for="currency in currencies.filter(
        (e) => e.code.toLowerCase().indexOf(filter) > -1
      )"
      class="col-6 col-sm-4 col-md-3 col-xl-2"
      :key="currency.id"
    >
      <label class="cstm-check dropdown-item">
        <span class="text-black mx-2 regular-14">{{ currency.code }}</span>
        <input
          type="checkbox"
          v-model="currency.enabled"
          v-on:change="
            changeOption(
              'currencies',
              currency.code,
              $event.currentTarget.checked
            )
          "
        />
        <span class="checkmark select mx-2"></span>
      </label>
    </div>
  </div>
  <div
    ref="exchangeRatesModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("currencies.exchangeRates") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row text-start custom-input whitebg-input">
            <div class="col-12 mb-3">
              <h5 class="mb-2">
                {{ $t("currencies.exchangeRates") }}
              </h5>
              <label class="cstm-check dropdown-item">
                <span class="text-black mx-2 regular-14">
                  {{
                    $t("currencies.dailyExchangeRateWithPercentageCorrection")
                  }}
                </span>
                <input type="radio" :value="1" v-model="exchangeType" /><span
                  class="checkmark mx-2"
                ></span>
              </label>
              <label class="cstm-check dropdown-item">
                <span class="text-black mx-2 regular-14">
                  {{ $t("currencies.fixedValues") }}
                </span>
                <input
                  type="radio"
                  v-model="exchangeType"
                  :value="2"
                /><span /><span class="checkmark mx-2"></span>
              </label>
            </div>
            <div class="col-12 mb-1" v-if="exchangeType === 1">
              <label class="form-label bold-12 text-gray">
                {{ $t("currencies.percentageCorrection") }}
              </label>
              <div class="fix-end-input">
                <input
                  type="number"
                  class="form-control"
                  v-model="exchangeCorrection"
                />
                <span>%</span>
              </div>
            </div>
            <div class="col-12 mb-3" v-if="exchangeType === 1">
              <label class="form-label bold-12 text-gray">
                {{ $t("currencies.exchangeRateSources") }}
              </label>
              <div class="row align-items-center mb-1" v-for="i in 3" :key="i">
                <div class="col-auto">
                  {{ $t(`currencies.key2`, { i: i }) }}
                </div>
                <div class="col">
                  <v-select
                    v-if="exchangeSource"
                    v-model="exchangeSourceVal[i]"
                    :options="exchangeSource"
                    label="name"
                    :reduce="(e) => e.id"
                    append-to-body
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="exchanges">
            <div
              class="row"
              v-for="c in exchanges.filter((e) => e.enabled)"
              :key="c.code"
            >
              <div
                class="col-12 row align-items-center"
                v-for="c2 in c.exchange_rates.filter(
                  (e) => e.converted_currency_code !== e.base_currency_code
                )"
                :key="c2.code"
              >
                <div class="col-3">1 {{ c.code }}</div>
                <div class="col-auto">=</div>
                <div
                  class="col custom-input whitebg-input mb-1"
                  v-if="exchangeType === 2"
                >
                  <div class="fix-end-input">
                    <input
                      type="number"
                      class="form-control"
                      v-model="c2.rate"
                    />
                    <span>{{ c2.converted_currency_code }}</span>
                  </div>
                </div>
                <div class="col" v-else>
                  {{ c2.rate }} {{ c2.converted_currency_code }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="saveExcange"
          >
            {{ $t("finance.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../../modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "Currencies",
  mounted() {
    http.fetch("/currencies/all").then((data) => {
      this.currencies = data;
    });
  },
  methods: {
    exchangeRateModal() {
      http.fetch("/currencies/exchange").then((data) => {
        this.exchanges = data.currencies;
        this.exchangeType = data.options.exchange_type;
        this.exchangeSource = data.sources;
        this.exchangeCorrection = data.options.exchange_correction;
        this.exchangeSourceVal = {};
        for (const s of this.exchangeSource) {
          if (s.order !== null) {
            this.exchangeSourceVal[s.order] = s.id;
          }
        }
      });

      this.addCharactericsModalw = new bootstrap.Modal(
        this.$refs.exchangeRatesModal,
        {
          backdrop: "static",
        }
      );
      this.addCharactericsModalw.show();
    },
    changeOption(type, id, enable, row = "code") {
      const method = enable ? "enable" : "disable";
      let data = {};
      data[row] = id;
      http.fetch(`/${type}/${method}`, data, true);
    },
    saveExcange() {
      let rows = {
        exchange_type: this.exchangeType,
        exchange_source: this.exchangeSourceVal,
        exchange_correction: this.exchangeCorrection,
      };
      if (this.exchangeType === 2) {
        rows.rates = [];
        for (const ex of this.exchanges) {
          for (const exs of ex.exchange_rates) {
            rows.rates.push({
              base: ex.code,
              converted: exs.converted_currency_code,
              rate: exs.rate,
            });
          }
        }
      }

      http.fetch(`/currencies/save`, rows, true).then((data) => {
        console.log(data);
      });
    },
  },
  data() {
    return {
      loadAddBtn: false,

      addCharacterics: {
        type: 1,
        name: {},
        prefix: {},
        postfix: {},
        schema_type: null,
      },
      filter: "",

      currencies: [],

      exchangeType: 1,
      exchangeSource: 0,
      exchangeCorrection: 0,
      exchanges: [],
      exchangeSourceVal: [],
    };
  },
};
</script>
